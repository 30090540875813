/*
  jera jera design system
*/

// neutrals
export const N900 = '#020203' // licorice 
export const N850 = '#1A191F' // eerie
export const N800 = '#33313B' // aresenic
export const N750 = '#4B4956' // gunpowder 
export const N700 = '#62606E' // smoke
export const N650 = '#787684' // dolphin
export const N600 = '#8B8996' // granite
export const N550 = '#9E9CAB' // battleship
export const N500 = '#AFADBC' // pearl
export const N450 = '#BEBCCB' // haze
export const N400 = '#CBC9D8' // heather
export const N350 = '#D7D5E4' // quicksilver
export const N300 = '#E2E0EC' // platinum 
export const N250 = '#EBEAF3' // solitude
export const N200 = '#F4F3F8' // whisper
export const N150 = '#F7F7FC' // quartz
export const N100 = '#FBFBFF' // ghost

// primaries
export const P900 = '#540522' // diesel
export const P850 = '#6E062B' // temptress
export const P800 = '#870633' // monarch
export const P750 = '#A0063B' // raspberry
export const P700 = '#B70743' // crimson
export const P650 = '#CC0A4A' // carmine
export const P600 = '#DF0F52' // ruby
export const P550 = '#F0165B' // razzmatazz
export const P500 = '#FF2066' // awesome
export const P450 = '#FF3876' // radical
export const P400 = '#FF5389' // watermelon
export const P350 = '#FF719E' // rose
export const P300 = '#FF92B4' // pink
export const P250 = '#FFB4CC' // carnation
export const P200 = '#FFD6E3' // flamingo
export const P150 = '#FFE7EE' // lavender
export const P100 = '#FCF8F9' // blush

// color tokens
export const textThemed = { dark: N800, light: N200 }
export const heading = N850
export const text = N800
export const subtleText = N700
export const code = N100
export const link = P650
export const background = N200
export const selectedTextBackground = P150
export const primary = P500
