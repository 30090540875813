import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'

const Nav = styled.nav`
  display: grid;

  grid-template-columns:
    1fr
    minmax(96px, 128px)
    1fr

  max-height: 168px;
  margin: 0 0 48px 0;
  padding: 0;

  ul {
    grid-column: 2;
    list-style: none;
    padding: 0;
  }

  li {
    margin: 0;  
  }

  img {
    height: 128px;
    width: 128px;
  }
`

export default () => (
  <Nav aria-label="Home">
    <ul>
      <li>
        <Link to="/"> 
          <img src={'/logo.png'} alt="Logo" />
        </Link> 
      </li>
    </ul>
  </Nav>
)
