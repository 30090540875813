import React, { useReducer, useEffect } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import './base.css'
import * as colors from './colors'
import Navigation from './Navigation'

const StyledPage = styled.main`
  background-color: ${colors.background};
  color: ${colors.text};
  overflow-x: hidden;
  
  * ::selection { 
    background-color: ${colors.selectedTextBackground};
  }
`

const Footer = styled.footer`
  align-items: center;
  background-color: ${colors.N850};
  display: flex;
  font-size: 14px;
  justify-content: center;
  margin-top: 48px;
  min-height: 128px;

  li {
    align-items: center;
    display: inline-flex;
    margin: 0 1em;  
    text-transform: uppercase;
  }

  span, a, a:visited, a:active {
    color: ${colors.N600};
    font-weight: 400;
    letter-spacing: .05em;
    text-decoration: none;
  }

  a:hover {
    color: ${colors.N500};
  }

  @media (max-width: 960px) {

  }
`

const Trace = styled.div`
  border: 1px dashed pink;
  position: fixed;
`

function reducer(state, action) {
  switch (action.type) {
    case 'toggle':
      return { isTraceVisible: !state.isTraceVisible }
    default:
      throw new Error()
  }
}

const Layout = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, { isTraceVisible: false });

  // global trace panel
  useEffect(() => {
    function downHandler({ key }) {
      if (key === '`') {  
        dispatch({type: 'toggle'})
      }  
    }

    window.addEventListener('keydown', downHandler)

    return () => {
      window.removeEventListener('keydown', downHandler);
    }
  }, [])

  return (
    <StyledPage>
      {
        state.isTraceVisible &&
        <Trace>
          <div>T R A C E</div>
          <div></div>
        </Trace>
      }
      <header>
        <Navigation />
      </header>
      {children}
      <Footer>
        <ul>
          <li>
            <Link to="/about/">hideous  pixels</Link>
          </li>
          <li>
            <Link to="https://twitter.com/jimlears">@jimlears</Link>
          </li>
          <li>
            <span>©2020 Jim Lears</span>
          </li>
        </ul>
      </Footer>
    </StyledPage>
  )
}

export default Layout
